// Generated by Framer (9f2fb1c)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, getFontsFromSharedStyle, getPropertyControls, RichText, SmartComponentScopedContainer, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";
import { Icon as Phosphor } from "https://framerusercontent.com/modules/tYScH7LTqUtz5KUaUAYP/p8dptk4UIND8hbFWz9V7/Phosphor.js";
import * as sharedStyle from "../css/XPCj9bjk6";
const PhosphorFonts = getFonts(Phosphor);
const PhosphorControls = getPropertyControls(Phosphor);

const serializationHash = "framer-gNTtr"

const variantClassNames = {SuzYD3tZh: "framer-v-17lw4r2"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, icon, id, title, width, ...props}) => { return {...props, Ct6w64bDR: icon ?? props.Ct6w64bDR ?? "House", DvlFsXyI6: title ?? props.DvlFsXyI6 ?? "Competitive salary and equity"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string;icon?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, DvlFsXyI6, Ct6w64bDR, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "SuzYD3tZh", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = [sharedStyle.className]

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-17lw4r2", className, classNames)} data-framer-name={"a perk"} layoutDependency={layoutDependency} layoutId={"SuzYD3tZh"} ref={refBinding} style={{...style}}><motion.div className={"framer-97ppdh"} data-framer-name={"inner_wrap"} layoutDependency={layoutDependency} layoutId={"SGUYB3SXy"}><ComponentViewportProvider ><SmartComponentScopedContainer className={"framer-1rscvlr-container"} isAuthoredByUser isModuleExternal layoutDependency={layoutDependency} layoutId={"OXBrzI99m-container"} nodeId={"OXBrzI99m"} rendersWithMotion scopeId={"MuVqy2EbU"}><Phosphor color={"rgb(8, 21, 46)"} height={"100%"} iconSearch={"House"} iconSelection={Ct6w64bDR} id={"OXBrzI99m"} layoutId={"OXBrzI99m"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} weight={"fill"} width={"100%"}/></SmartComponentScopedContainer></ComponentViewportProvider><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-6u09pm"} data-styles-preset={"XPCj9bjk6"} style={{"--framer-text-color": "var(--extracted-r6o4lv, rgb(7, 22, 46))"}}>Competitive salary and equity</motion.p></React.Fragment>} className={"framer-1lhyca7"} data-framer-name={"Competitive salary and equity"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"WMp1iIXNN"} style={{"--extracted-r6o4lv": "rgb(7, 22, 46)"}} text={DvlFsXyI6} verticalAlignment={"top"} withExternalLayout/></motion.div></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-gNTtr.framer-njzlej, .framer-gNTtr .framer-njzlej { display: block; }", ".framer-gNTtr.framer-17lw4r2 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 12px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px; position: relative; width: 400px; }", ".framer-gNTtr .framer-97ppdh { align-content: flex-start; align-items: flex-start; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 12px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px; position: relative; width: 100%; }", ".framer-gNTtr .framer-1rscvlr-container { flex: none; height: 24px; position: relative; width: 24px; }", ".framer-gNTtr .framer-1lhyca7 { flex: 1 0 0px; height: auto; position: relative; white-space: pre-wrap; width: 1px; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-gNTtr.framer-17lw4r2, .framer-gNTtr .framer-97ppdh { gap: 0px; } .framer-gNTtr.framer-17lw4r2 > *, .framer-gNTtr .framer-97ppdh > * { margin: 0px; margin-left: calc(12px / 2); margin-right: calc(12px / 2); } .framer-gNTtr.framer-17lw4r2 > :first-child, .framer-gNTtr .framer-97ppdh > :first-child { margin-left: 0px; } .framer-gNTtr.framer-17lw4r2 > :last-child, .framer-gNTtr .framer-97ppdh > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 200
 * @framerIntrinsicWidth 400
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"DvlFsXyI6":"title","Ct6w64bDR":"icon"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 * @framerColorSyntax true
 */
const FramerMuVqy2EbU: React.ComponentType<Props> = withCSS(Component, css, "framer-gNTtr") as typeof Component;
export default FramerMuVqy2EbU;

FramerMuVqy2EbU.displayName = "a perk";

FramerMuVqy2EbU.defaultProps = {height: 200, width: 400};

addPropertyControls(FramerMuVqy2EbU, {DvlFsXyI6: {defaultValue: "Competitive salary and equity", displayTextArea: false, title: "Title", type: ControlType.String}, Ct6w64bDR: PhosphorControls?.["iconSelection"] && {...PhosphorControls["iconSelection"], defaultValue: "House", description: undefined, hidden: undefined, title: "Icon"}} as any)

addFonts(FramerMuVqy2EbU, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...PhosphorFonts, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})